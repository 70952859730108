<template>
  <div class="goods-list-confirm">
    <div class="item combo-info-content" v-if="info.seat !== 0 || info.time !== 0">
      <div class="combo-info" v-if="info.title">
        <div class="combo-name">
          <span class="name">{{ info.title }}</span>
        </div>
        <div class="num-time">
          <div class="num" v-if="info.seat">{{ info.title.indexOf('升级') !== -1 ? '增加' : '' }}设备数量 X{{
              info.seat
            }}
          </div>
          <div class="num" v-else>设备数量 X{{
              info.oldSeat
            }}
          </div>
          <div class="time" v-if="info.time">时长：{{ info.time }}个月</div>
        </div>
      </div>
      <div class="right">
        <div class="price">￥{{ $filters.toYuan(info.nowPrice, '商城') }}</div>
        <div class="old-price" v-if="info.oldPrice !== info.nowPrice">原价
          <span>￥{{ $filters.toYuan(info.oldPrice, '商城') }}</span></div>
      </div>
    </div>
    <div class="item dp_f" v-for="(item,index) in list" :key="index">
      <div class="left">
        <img :src="item.imgUrl" alt="位元灵感" v-if="item.type === 2" class="goods-img"/>
        <div class="top">
          <div class="goods-name">
            <span class="name">{{ item.title }}</span>
            <span class="num" v-if="item.type !== 3">{{ ' ' }} {{ item.count }}{{ item.unit }}</span>
          </div>
          <div class="sub-desc" v-if="item.type === 3">升级剩余套餐内{{ item.count }}天</div>
        </div>
      </div>
      <div class="right">
        <div class="price">￥{{ $filters.toYuan(item.price, '商城') }}</div>
        <div class="old-price" v-if="item.oldPrice">原价 <span>￥{{ $filters.toYuan(item.oldPrice, '商城') }}</span></div>
      </div>
    </div>
    <div class="item dp_f" v-if="total?.total">
      <div class="left"></div>
      <div class="price">
        <div class="text">合计金额</div>
        <div class="num">￥{{ total.total }}</div>
        <div class="sun-num" v-if="total.diff !== '0'">(优惠码省<span>￥{{ total.diff }}</span>)</div>
      </div>
    </div>
  </div>
</template>

<script setup>
import {getCurrentInstance, toRefs} from "vue";

const props = defineProps({
  list: {
    type: Array,
    default: () => [],
    required: false
  },
  info: {
    type: Object,
    default: () => {
    },
  },
  total:{
    type:Object,
    default: () => {
    },
    required: false
  }
})
const {list, total, info} = toRefs(props);
const {proxy} = getCurrentInstance();
</script>
<script>
import {defineComponent} from "vue";

export default defineComponent({
  name: "goodsList",
});
</script>

<style lang="scss" scoped>
.goods-list-confirm {
  padding: 0 40px;
  background-color: $shopBgColor;
  border-radius: 8px;

  .item {
    padding: 16px 0;
    color: $shopFsColor;

    &.combo-info-content {
      display: flex;
      justify-content: space-between;
      align-items: flex-start;
      padding: 30px 0;
    }

    &:not(:last-child) {
      border-bottom: 1px dashed #C4C4CC;
    }

    .combo-info {
      font-size: 18px;
      display: flex;
      .name {
        font-weight: 600;
        margin-right: 100px;
      }

      .num-time {

      }

      .time {
        margin-top: 14px;
        font-size: 14px;
        color: #797A80;
      }
    }

    .left {
      display: flex;
      align-items: center;
      font-size: 18px;
      .top{
        margin: 20px 0;
      }

      .goods-img {
        width: 120px;
        height: 120px;
        margin-right: 64px;
      }

      .goods-name{
        line-height: 1.5;
      }

      .name {
        font-weight: 600;
      }

      .num {
        margin-left: 16px;
      }

      .sub-desc {
        color: $shopFsColor2;
        margin-top: 10px;
      }
    }
    .price {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      div {
        line-height: 1;
      }

      .text {
        font-size: 18px;
        color: $shopFsColor;
        font-weight: 600;
      }

      .num {
        font-size: 24px;
        margin-left: 8px;
        color: $mainColor;
      }

      .sun-num {
        margin-left: 8px;
        color: $shopFsColor2;
        font-size: 16px;
        position: relative;
        top: 4px;

        span {
          color: $shopFsColor;
        }
      }
    }
    .right {
      .price {
        font-size: 20px;
        color: $shopFsColor;
        text-align: right;
      }

      .old-price {
        margin-top: 10px;
        font-size: 14px;
        color: $shopFsColor2;

        span {
          text-decoration: line-through;
        }
      }
    }
  }
}
</style>
